<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">Consider the reactions below:</p>
      <div class="mb-4 pl-6">
        <table>
          <tr>
            <td>
              <chemical-latex content="A2 + 2 C2 -> 2 AC2" />
            </td>
            <td style="width: 50px" />
            <td>
              <stemble-latex content="$\Delta\text{H} = $" />
              <number-value :value="enthalpy1" unit="\text{ kJ/mol}" />
            </td>
          </tr>
          <tr>
            <td>
              <chemical-latex content="A2 + 3 B2 -> 2 AB3" />
            </td>
            <td style="width: 50px" />
            <td>
              <stemble-latex content="$\Delta\text{H} = $" />
              <number-value :value="enthalpy2" unit="\text{ kJ/mol}" />
            </td>
          </tr>
          <tr>
            <td>
              <chemical-latex content="2 B2 + C2 -> 2 B2C" />
            </td>
            <td style="width: 50px" />
            <td>
              <stemble-latex content="$\Delta\text{H} = $" />
              <number-value :value="enthalpy3" unit="\text{ kJ/mol}" />
            </td>
          </tr>
        </table>
      </div>

      <p class="mb-3">
        Determine the enthalpy change for the following reaction using the information above.
      </p>

      <p class="mb-4 pl-6">
        <chemical-latex content="4 AB3 + 7 C2 -> 6 B2C + 4 AC2" />
      </p>

      <calculation-input
        v-model="inputs.rxnEnthalpy"
        prepend-text="$\Delta\text{H:}$"
        append-text="$\text{kJ/mol}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import NumberValue from '../NumberValue';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question79',
  components: {
    ChemicalLatex,
    NumberValue,
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        rxnEnthalpy: null,
      },
    };
  },
  computed: {
    enthalpy1() {
      return this.taskState.getValueBySymbol('enthalpy1').content;
    },
    enthalpy2() {
      return this.taskState.getValueBySymbol('enthalpy2').content;
    },
    enthalpy3() {
      return this.taskState.getValueBySymbol('enthalpy3').content;
    },
  },
};
</script>
